import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import Rollbar from 'rollbar';
import {
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import {
  Clear,
} from '@material-ui/icons';
import { SnackbarProvider } from 'notistack';
import { ConnectedRouter } from 'connected-react-router';

import configureStore, { history } from '../../core/store';

import ProtectedRoutes from '../ProtectedRoutes';
import AuthenticationRoutes from '../AuthenticationRoutes';
import './App.sass';

import CenteredInPageCircularProgress from '../CenteredInPageCircularProgress';
import UsersContainer from '../../containers/UsersContainer';
import { SnackbarUtilsConfigurator } from '../../utils/snackbar';
const AuthenticationContainer = lazy(() => import('../../containers/AuthenticationContainer'));
const OntologyManager = lazy(() => import('../../containers/OntologyManager'));
const ListeDiffusion = lazy(() => import('../../containers/ListeDiffusion'));
const Logout = lazy(() => import('../../containers/Logout'));


const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        maxWidth: '600px',
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: '#e4e4fa',
          fontWeight: '500',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#002123',
      main: '#002123',
    },
    secondary: {
      light: '#f3681f',
      main: '#f3681f',
    },
  },
});

const store = configureStore();

// On va définir un cas particulier sur la sélection de texte, afin
// qu'on puisse faire de la sélection multiple de checkbox avec Shift
// sans sélectionner le texte
['keyup', 'keydown'].forEach((event) => {
  window.addEventListener(event, (e) => {
    document.onselectstart = () => !(e.key === 'Shift' && e.shiftKey);
  });
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.notistackRef = React.createRef();
    this.state = {
      rollbar: new Rollbar({
        enabled: process.env.NODE_ENV === 'production',
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.NODE_ENV,
        },
      }),
    };
  }

  render() {
    const { rollbar } = this.state;
    return (
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={this.notistackRef}
        action={key => (
          <Button onClick={() => { this.notistackRef.current.closeSnackbar(key); }}>
            <Clear />
          </Button>
        )}
      >
        <SnackbarUtilsConfigurator />
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Router>
                <Suspense fallback={<CenteredInPageCircularProgress />}>
                  <Switch>
                    { /* protégé */ }
                    <ProtectedRoutes
                      path="/users"
                      component={UsersContainer}
                      rollbar={rollbar}
                      onlyAdmin
                    />
                    <ProtectedRoutes
                      path="/liste-diffusion"
                      component={ListeDiffusion}
                      rollbar={rollbar}
                      onlyAdmin
                    />
                    <ProtectedRoutes
                      path="/ontology-manager"
                      exact
                      component={OntologyManager}
                      rollbar={rollbar}
                    />
                    <ProtectedRoutes
                      path="/ontology-manager/:natureId/tree/:treeId/node/:nodeId"
                      component={OntologyManager}
                      rollbar={rollbar}
                    />
                    <ProtectedRoutes
                      path="/ontology-manager/:natureId/tree/:treeId"
                      component={OntologyManager}
                      rollbar={rollbar}
                    />
                    <ProtectedRoutes
                      path="/logout"
                      component={Logout}
                      rollbar={rollbar}
                    />

                    { /* non accessible si loggué */ }
                    <AuthenticationRoutes
                      exact
                      path="/login"
                      component={AuthenticationContainer}
                      rollbar={rollbar}
                    />

                    { /* Default */ }
                    <Redirect
                      to="/ontology-manager"
                    />
                  </Switch>
                </Suspense>
              </Router>
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </SnackbarProvider>
    );
  }
}

export default App;
