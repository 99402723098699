
export const API_ENTRY_POINT = GLOBAL_API_ENTRY_POINT || process.env.REACT_APP_API_ENTRY_POINT;
export const BYPASS_AUTH = process.env.REACT_APP_BYPASS_AUTH === 'true';

export const forbiddenChars = ['~', ';', ',', '|', '\t'];

export const availableLangs = [
  'fr',
  'en',
];

export const fieldsWhitelist = {
  nodes: [
    'append',
    'children',
    'comment',
    'count',
    'force_relation',
    'found',
    'id',
    'isReverse',
    'level',
    'label',
    'lang',
    'nature',
    'nb_keywords',
    'nb_keywords_self',
    'nb_suggestions',
    'origin_id',
    'parent_id',
    'path',
    'pathFile',
    'type',
  ],
  keywords: [
    'comment',
    'count',
    'force_relation',
    'id',
    'isReverse',
    'label',
    'lang',
    'nature',
    'nb_suggestions',
    'origin_id',
    'parent_id',
    'source_id',
    'type',
  ],
}

export const defaultOntology = {
  driver: 'elastic:XXXXXXX:/usr/software/conf/elastic-certificates.p12:/usr/software/conf/elastic-certificates.p12',
  fieldDisplay: 'IDQES,TITLE,TEXTE,snippets,tout',
  fieldFacets: 'QES_Unknown.verbatim,QES_Coldconcept.verbatim',
  fieldHighlight: '*',
  fieldIdentifier: 'IDQES',
  fieldSearch: 'TEXTE',
  categoriesSourceDestination: 'Person,Company,Organization,Organonoff,Location,Event,Product,Object,Concept',
  fieldSuggestion: 'QES_Concept,QES_Coldconcept',
  fieldTitle: 'fieldTitle',
  label: '',
  nbcontain: 50,
  nbsim: 50,
  nbsugg: 50,
  show_admin: true,
  show_create: false,
  show_delete: false,
  show_export: true,
  show_force: false,
  show_import_elastic: true,
  show_import_fichier: true,
  show_import_relation: true,
  show_import_suggestion: true,
  show_import_unique: true,
  show_import_wikidata: true,
  show_knn: false,
  show_purge: false,
  show_relation: false,
  show_reverse: false,
  show_suggestion: false,
  show_update: false,
  urlEnrich: 'http://enrich.qwamci.com/qesenrich/usr/FRA/jsp/QESEnrichissement.jsp',
  urlPhrase: 'http://localhost:9200/mabase_phrases',
  urlRelation: 'http://localhost:9200/mabase_relations',
  urlSearch: 'http://localhost:9200/mabase',
};

export const CIVILITY = {
  1: 'Madame',
  2: 'Mademoiselle',
  3: 'Monsieur',
  1000: 'Non précisé',
};

export const defaultUser = {
  civilite: 1,
  email: '',
  logon: '',
  utilisateur: null,
  nom: '',
  prenom: '',
};

export const defaultRight = {
  show_admin: false,
  show_create: false,
  show_delete: false,
  show_export: false,
  show_import_elastic: false,
  show_import_fichier: false,
  show_import_relation: false,
  show_import_suggestion: false,
  show_import_unique: false,
  show_import_wikidata: false,
  show_purge: false,
  show_suggestion: false,
  show_test: false,
  show_update: false,
};


export const OPENED_USER_REQUEST = '1';
export const CLOSED_USER_REQUEST = '2,3,4';
export const OPEN_USER_STATUS = 1;
export const CLOSED_USER_STATUS = 3;
